import { createPopper } from '@popperjs/core';
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("jquery")
require("moment")
let Trix = window.Trix = require("trix")
require("@rails/actiontext")
require("@fortawesome/fontawesome-pro")

// load bootstrap 4
import 'bootstrap/dist/js/bootstrap';

require("pdfobject")
// require("formBuilder")

// load the images
import '../application/images/index.js.erb';
